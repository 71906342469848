<template>
  <div class="qr-con">
    <div class="code-card">
      <div class="top-header">
        <div class="header-main">
          <img :src="myInfo.headimgurl" alt="微信头像" />
          <div class="qr-info">
            <span>{{myInfo.real_name}}</span>
            <span>{{myInfo.mobile}}</span>
          </div>
        </div>
        <div class="top-header-icon" @click="getUserCode()">
          <img src="@static/vip/shuaxin.png" alt="">
        </div>
      </div>
      <div class="qr-img">
        <img :src="code_info.user_code" alt="">
      </div>
      <span class="bot-info">员工扫描上面二维码进行核销</span>
    </div>
  </div>
</template>
<script>
import UserInfo from "@/api/user";
import QRCode from 'qrcode'
export default {
  data() {
    return {
      myInfo: {
        wallet: {}
      },
      show_num: 0,
      timer: null,
      user_info: "",
      initHeader: require('@static/vip/init-head.png'),
      code_info: {
        user_code: ""
      }
    };
  },
  created() {
    this.getInfo();
    this.getUserCode();
  },
  methods: {
    async getInfo() {
      try {
        const res = await UserInfo.getUserMember();
        console.log(res)
        if (res.code == 200) {
          if (!res.data.headimgurl) res.data.headimgurl = this.initHeader;
          this.myInfo = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取用户二维码
    async getUserCode() {
      try {
        const res = await UserInfo.getUserQrcode()
        if (res.code === 200) {
          this.code_info = res.data
          await this.generateQR(res.data.user_code)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async generateQR(data) {
      try {
        this.code_info.user_code = await QRCode.toDataURL(data)
      } catch (err) {
        this.$toast('生成二维码失败');
        console.error(err)
      }
    }
  },
  destroyed() {
    this.timer = null
    clearInterval(this.timer)
  }
};
</script>
<style lang="scss" scoped>
.qr-con {
  width: 100%;
  height: 100vh;
  padding: 30% 10px;
  background-color: #f6f6f6;
  > .code-card {
    width: 100%;
    height: auto;
    border-radius: 9px;
    background: #fff;
    padding-top: 20px;
    position: relative;
    > .top-header {
      width: 92%;
      height: 80px;
      margin: 0 auto;
      display: flex;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;
      .header-main {
        flex: 1;
        display: flex;
        align-items: center;
        > img {
          width: 80px;
          height: 80px;
          border-radius: 6px;
        }
        > .qr-info {
          display: flex;
          height: 55px;
          flex-direction: column;
          margin-left: 10px;
          font-size: 12px;
          justify-content: space-between;
          span:nth-child(1) {
            font-size: 14px;
            color: #000;
          }
        }
      }
      // & img {
      //   width: 70px;
      //   height: 70px;
      //   border-radius: 6px;
      // }
      .top-header-icon {
        margin-left: 10px;
        width: 30px;
        height: 30px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
    > .qr-img {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: aquamarine;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    > .bot-info {
      width: 100%;
      height: 30px;
      position: absolute;
      display: inline-block;
      line-height: 30px;
      color: #363636;
      text-align: center;
      bottom: -40px;
      background-color: #eeeeee;
    }
  }
}
</style>

